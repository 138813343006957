import React from 'react';
import { Layout } from '../../components';

const B2BTermsAndConditions = () => (
  <Layout pageTitle="B2B General Terms and Conditions">
    <section className="legal-section bg-dark">
      <div className="container">
        <div className="row">
          <div>
            <h1 className="text-white text-center">
              General Terms and Conditions for B2B
            </h1>
            <div className="text-justify text-white-60 col-lg-10 mx-auto">
              <p className="font-italic text-white text-left">
                Last update on June 14, 2022.
              </p>
              <h3 className="text-white text-left">1. The meaning of terms</h3>
              <p>
                <b>Client/Business entity</b> - legal entity, state authority,
                state administration body, local self-government unit,
                association, and society (sports, cultural, charitable, etc.),
                represented by a person authorised to represent, and a physical
                person acting in the field of their economic activities
              </p>

              <h3 className="text-white text-left">
                2. General information about the Services
              </h3>
              <p>
                With these General Terms and Conditions, Cobalt Hills Tech Ltd
                company for computer activities and services, Pod vrh 7, Cernik,
                Čavle, PIN: 25966171359 ("Company") as a service provider
                regulates the business and contractual relations between the
                Company and the business users of the Services ("Client") as a
                service recipient in the field of software programming services
                and other similar activities that are part of Company's
                services.
              </p>
              <p>
                The Company provides the following services:
                <ul>
                  <li>web development</li>
                  <li>mobile development</li>
                  <li>machine learning</li>
                  <li>quality assurance</li>
                  <li>IT consulting</li>
                  <li>education</li>
                </ul>
              </p>
              <p>
                The Services are provided under Croatian legal regulations and
                the Client will be, for each service, provided with detailed and
                accurate information in an accurate and not misleading way.
              </p>
              <p>
                The Services are provided with the care of a good businessman
                and the Company will assure that the Services are delivered in
                time.
              </p>

              <h3 className="text-white text-left">3. Terms of use</h3>
              <p>
                These General Terms and Conditions are referred to business
                entities and are given to the business entities before providing
                the Services.
              </p>
              <p>
                For the purpose of provision of Services, the Client will
                provide the Company with the information necessary for the order
                to be executed and/or for the conclusion of the contract.
              </p>
              <p>
                The Client is obliged to inform the Company of any change in
                status data, name, address, ownership structures, filing a
                bankruptcy petition, proposals for the opening of bankruptcy
                proceedings, opening bankruptcy proceedings or liquidation
                proceedings, and other changes occurred that can make an impact
                on the services and payments for the services after the services
                have been ordered. The notification is only valid as a written
                statement.
              </p>
              <p>
                The Client agrees that the Company may freely transfer the
                contracted work to its employees and other subcontractors. The
                Company may decide to bypass this rule if the nature of the
                business requires so.
              </p>
              <p>
                The Client cannot transfer any of its rights or obligations
                under these General Terms and Conditions to any other company or
                entity without the Company's prior written consent. If the
                nature of the business requires so or if it is necessary because
                of the Client's business status and/or status changes, the
                Company may decide to bypass this rule. If the services are
                delivered by the Company and paid in full by the Client this
                term is not applicable.
              </p>
              <p>
                If the nature of the business requires reporting on the results
                of the Services in certain timeframes, stages of progress,
                surveillance over the Services by the Client, or instructions
                from the Client such as technical specifications, the Company
                will deliver those reports to the Client, approve surveillance
                and receive the instructions according to the Article 593. of
                Croatian Law of Obligations.
              </p>

              <h3 className="text-white text-left">4. Contracting / orders</h3>
              <p>
                Orders are made in writing or in another method of
                communication.
              </p>
              <p>
                If the Services that are provided are long-lasting, the Client
                and the Company are obliged to sign a business cooperation
                contract for the provision of Services that can be terminated at
                any time by the Company or by the Client upon a 30-day prior
                written notice. The Company may agree to bypass these rules if
                the nature of the business requires it.
              </p>
              <p>
                The Client is not entitled to terminate contract or services if:
                <ul>
                  <li>
                    the Services are fully fulfilled and the Client gave
                    explicit prior consent for service delivery and its
                    confirmation that it is aware of the fact that it will lose
                    the right to terminate the contract if the services are
                    fully fulfilled. It is considered that the Client has given
                    its consent when it ordered the services or when it entered
                    into a service contract
                  </li>
                  <li>
                    the Services are made according to the Client’s
                    specification or are adapted to the Client
                  </li>
                  <li>and in other legally-established cases</li>
                </ul>
              </p>
              <p>
                The Company and the Client may agree on these and other terms in
                their mutual contract.
              </p>

              <h3 className="text-white text-left">
                5. A right to refuse the conclusion of a contract and/or to
                provide the Services
              </h3>
              <p>
                The Company may refuse to sign a contract or provide Services in
                the following situations:
                <ul>
                  <li>If the Client does not pay 2 consecutive invoices</li>
                  <li>
                    If the Client uses or wants to use the Services provided for
                    illegal purposes
                  </li>
                  <li>If the Client damages the reputation of the Company</li>
                  <li>In other statutory cases</li>
                </ul>
              </p>

              <h3 className="text-white text-left">
                6. Place of service, delivery time, intellectual property,
                confidential information
              </h3>
              <p>
                The Company determines the place, the time, and how the Services
                are performed. If it is necessary, the Client must also be able
                to provide access to its premises and equipment, and respond to
                inquiries of the Company.
              </p>
              <p>
                The Company undertakes to provide its Services within a
                reasonable and/or agreed timeframe, except in case of a higher
                power, illness, and other justifiable cases of which the Client
                will be notified in time.
              </p>
              <p>
                The Company is authorised to make partial delivery of the
                Services unless a special written contract between the Client
                and the Company excludes the partial delivery of the Services.
              </p>
              <p>
                Any information or work in progress, trade secrets, or any other
                materials related to the business or project of the Client and
                the Company, including, without limitation, all computer codes
                (source codes, objects, and executable) and all associated
                algorithms, diagrams, charts, descriptions, and other
                documentation, constitute confidential Information (hereinafter,
                the Confidential Information). Company and the Client shall not,
                either during the provision of Services or at any time
                thereafter, use, copy or disclose to any third party any such
                Confidential Information, unless such use, copying, or
                disclosure has been authorised in advance in writing by the
                Company or Client.
              </p>
              <p>
                The Client undertakes to respect the intellectual property
                rights of the Company.
              </p>
              <p>
                If the Services are performed for the Client as “work for hire”
                (computer program, code, etc.), upon the payment of the
                Services, the Company will pass all the intellectual property
                rights to the Client.
              </p>
              <p>
                The Client acknowledges that during the time that the Services
                are provided, the Company and the Company Personnel may ask for
                access to Confidential Information and/or information of a
                proprietary nature to the Client that is necessary for providing
                the Services and the Client agrees to provide said information
                to the Company and the Company Personnel.
              </p>
              <p>
                The Company and the Company Personnel undertake to respect the
                intellectual property rights of the Client.
              </p>
              <p>
                Parties hereby grant each other the right to use and display
                each other’s name and logo ("Trademarks") for promotional means
                on the respective websites or other promotional material,
                however, restricted solely in connection with the Services
                provided. Any usage under this clause shall be done according to
                the proprietor Party’s guidelines as they may be provided from
                time to time. Neither Party shall use the other Party’s
                Trademarks in any manner that could disparage, harm, or
                otherwise damage the other Party’s goodwill in its Trademarks.
                The Party using the Trademarks shall not, at any time, misuse
                the same or present itself as an affiliate or other legal agent
                of the Party whose Trademarks are being used. Any rights and
                linked usage of Trademarks granted under this Section shall be
                immediately discontinued in the event the Services are
                terminated.
              </p>
              <p>
                All content, trademarks, and data on the Company website,
                including software, databases, text, graphics, icons,
                hyperlinks, private information, and designs are the property of
                or licensed to the Company, and as such, are protected from
                infringement by domestic and international legislation and
                treaties. Subject to the rights afforded to the Client in these
                General Terms, all other intellectual property rights on this
                website are expressly reserved.
              </p>
              <p>
                Content from the Company website may not be used or exploited
                for any commercial purposes without Company’s prior written
                consent.
              </p>

              <h3 className="text-white text-left">7. Prices and payments</h3>
              <p>
                The Company may charge its service on the bias of billable hours
                and/or as a fixed fee, depending on the contract with the
                Client. The price of the Services does not include the material
                costs. The Company has the right to charge additional material
                costs.
              </p>
              <p>
                The price of the Services is formed according to the scope of
                the Services provided.
              </p>
              <p>
                Prices are delivered to the Client through an offer, by
                displaying the price list or other methods, and the prices shown
                in the price list are subject to change. The price specified in
                the offer containing all the essential legal elements of the
                offer is binding for a maximum of 45 days from the date of
                issuance, or less, depending on the offer.
              </p>
              <p>
                For the work performed, the Company is obliged to invoice the
                Client. Invoices are printed on a computer. The invoice is
                issued when the Service is delivered. If there are multiple
                deliveries of the Services, then the Company may charge the
                Client for each delivery or may invoice the Client at the end of
                the current month, which is taken as an accounting period by the
                law of the Republic of Croatia.
              </p>
              <p>
                The Client is obliged to pay the invoice according to the
                indicative maturity, in case of late payment the Company has all
                rights to ask for interest.
              </p>

              <h3 className="text-white text-left">
                8. Liability, actions and complaints
              </h3>
              <p>
                By accepting these General Terms and Conditions, the Client
                accepts that the Company provides Services to them within its
                technical and operational capabilities.
              </p>
              <p>
                The Company is obliged to inform the Client about any
                difficulties in the realisation of the contracted work.
              </p>
              <p>
                If due to the Client's action for which it is responsible, there
                is an unexpected extension of the performance of the Services,
                the Client will not be exempted from its obligation to pay the
                costs to the Company.
              </p>
              <p>
                The Company is not obliged to pay compensation if the level of
                quality of the Services performed is less than the prescribed
                level of quality of the service due to objective causes that
                could not have been predicted or avoided or eliminated (higher
                power) or are conditional on the Client's will or actions.
              </p>
              <p>
                The Client can file a reasonable complaint about the quality of
                the Services that are provided within 3 working days after the
                Services are provided or the result of the Services are
                delivered. If the Client does not file a complaint within the
                specified period, it will be considered that the Services are
                provided and that the results of the Services are delivered
                without any defects.
              </p>
              <p>
                If the Client has filed a reasonable complaint about the quality
                of the Service performed, it may request to perform subsequent
                works to meet the quality criteria, and based on these General
                Terms and Conditions if it is determined that the quality of the
                Service performed is less than the quality prescribed by the
                applicable regulations in the Republic of Croatia and these
                General Terms and Conditions. In that case, the Company
                undertakes to address all deficiencies at its own expense as
                soon as possible and to inform the Client.
              </p>
              <p>
                If the Client has a complaint about a specific part of the
                Service provided, it is obliged to pay the amount of the invoice
                in relation to the undisputed service provided.
              </p>
              <p>
                If the Client cancels the order for which the Services have
                already been made, the Client is obliged to compensate for the
                damage caused.
              </p>
              <p>
                The Client is liable for any omissions or damages which may
                arise from non-compliance with the obligation to provide data on
                the resulting changes, as well as due to non-compliance with the
                obligation to provide any other data requested by the Company
                that is necessary for providing the Services.
              </p>

              <h3 className="text-white text-left">9. Other</h3>
              <p>
                The Company reserves the right to change these General Terms and
                Conditions in accordance with changes in legal provisions,
                business policies of the Company, or other circumstances that
                may have an affect on how the Services are provided. In case of
                such changes, the Company will inform the Client about it on the
                website and update the date of modification of these General
                Terms and Conditions.
              </p>
              <p>
                For anything that is not governed by these General Terms and
                Conditions or a contract with the Client, the relevant Croatian
                legal regulations will be in force.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default B2BTermsAndConditions;
